import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  api_link = environment.api_link+"/user";
  // api_link = "https://sokna.onrender.com/user";

  constructor(
    private http: HttpClient
  ) { }

  login(req){
    // console.log('req',req);
    let api = "/authenticate";
    return this.http.post(this.api_link+api, req);
  }

  register(req:any){
    return this.http.post(this.api_link, req);
  }

  findByEmail(req:any){
    let api = "/email";
    return this.http.post(this.api_link+api, req);
  }

  find_user_by_id(req:any){
    let id = "/"+req.id;
    return this.http.get(this.api_link+id);
  }

  update_informations(req:any){
    let id = "/"+req.id;
    return this.http.patch(this.api_link+id, req);
  }
}
