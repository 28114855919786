import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserSettingsService {

  // api_link = "https://sokna.onrender.com/user-settings";
  api_link = environment.api_link+"/user-settings";

  constructor(
    private http: HttpClient
  ) { }

  create(req:any){
    return this.http.post(this.api_link, req);
  }

  get_user_settings(req:any){
    return this.http.get(this.api_link+'/'+req.id);
  }

  update_settings(req:any){
    let id = "/"+req.id;
    return this.http.patch(this.api_link+id, req);

  }
}
