import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree, NavigationExtras, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(public router: Router, private storage: Storage) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      let navex: NavigationExtras = {
        replaceUrl: true
      };
      return this.storage.get("user").then((user: any) => {
        if (!user) {
          this.router.navigate(["/login"], navex);
          // window.location.href = 'https://welcome.renty.com.co/';
          return false;
        } else {
          if (user.status == "banned") this.router.navigate(["/inactive"], navex);
          else return true;
        }
      });
  }

}
