// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-fab {
  margin: 40px 20px;
}

ion-popover {
  --height: auto;
  --width: 400px;
}

.content_popover {
  padding: 10px;
  margin-bottom: 100px;
}
.content_popover .message_wapper {
  display: flex;
  align-items: center;
}
.content_popover .message_wapper .bot-icon {
  width: 20px;
}

.user_message {
  display: flex;
  justify-content: end;
}

.footer {
  position: fixed;
  width: 100%;
  bottom: 0;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10000;
  background-color: #fff;
}
.footer ion-icon {
  color: var(--ion-color-primary);
  font-size: 25px;
  padding: 20px;
  cursor: pointer;
}
.footer textarea {
  display: block;
  width: 100%;
  max-height: 200px;
  margin: 0;
  padding: 15px 85px 15px 20px;
  background-color: transparent;
  border: none;
  line-height: 1.4;
  font-size: 15px;
  resize: none !important;
  overflow-x: hidden;
  overflow-y: auto;
  word-wrap: break-word;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  scrollbar-width: thin;
  scrollbar-color: #babac0 transparent;
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;AACJ;;AAEA;EACI,cAAA;EACA,cAAA;AACJ;;AAEA;EACI,aAAA;EACA,oBAAA;AACJ;AAAI;EACI,aAAA;EACA,mBAAA;AAER;AADQ;EACI,WAAA;AAGZ;;AAEA;EACI,aAAA;EACA,oBAAA;AACJ;;AAEA;EACI,eAAA;EACA,WAAA;EACA,SAAA;EACA,eAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,cAAA;EACA,sBAAA;AACJ;AAAI;EACI,+BAAA;EACA,eAAA;EACA,aAAA;EACA,eAAA;AAER;AAAI;EACI,cAAA;EACA,WAAA;EACA,iBAAA;EACA,SAAA;EACA,4BAAA;EACA,6BAAA;EACA,YAAA;EACA,gBAAA;EACA,eAAA;EACA,uBAAA;EACA,kBAAA;EACA,gBAAA;EACA,qBAAA;EACA,wBAAA;EACA,qBAAA;EACA,gBAAA;EACA,qBAAA;EACA,oCAAA;AAER","sourcesContent":["ion-fab {\n    margin: 40px 20px;\n}\n\nion-popover {\n    --height: auto;\n    --width: 400px;\n}\n\n.content_popover {\n    padding: 10px;\n    margin-bottom: 100px;\n    .message_wapper {\n        display: flex;\n        align-items: center;\n        .bot-icon {\n            width: 20px;\n        }\n    }\n}\n\n.user_message {\n    display: flex;\n    justify-content: end;\n}\n\n.footer {\n    position: fixed;\n    width: 100%;\n    bottom: 0;\n    padding: 0 20px;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    z-index: 10000;\n    background-color: #fff;\n    ion-icon {\n        color: var(--ion-color-primary);\n        font-size: 25px;\n        padding: 20px;\n        cursor: pointer;\n    }\n    textarea {\n        display: block;\n        width: 100%;\n        max-height: 200px;\n        margin: 0;\n        padding: 15px 85px 15px 20px;\n        background-color: transparent;\n        border: none;\n        line-height: 1.4;\n        font-size: 15px;\n        resize: none !important;\n        overflow-x: hidden;\n        overflow-y: auto;\n        word-wrap: break-word;\n        -webkit-appearance: none;\n        -moz-appearance: none;\n        appearance: none;\n        scrollbar-width: thin;\n        scrollbar-color: #babac0 transparent;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
