import { Component, OnInit, ViewChild } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Geolocation } from '@capacitor/geolocation';
import { register } from 'swiper/element/bundle';
import { Platform, IonPopover } from '@ionic/angular';
import { Router } from '@angular/router';
import { Plugins } from '@capacitor/core';
const { SplashScreen } = Plugins;
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import { ToastController } from '@ionic/angular';
import { ApiService } from './services/api.service';
import { UserSettingsService } from './services/user-settings.service';
import { LanguageService } from './services/language.service';



register();
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

  @ViewChild('popover') popover: IonPopover;

  constructor(
    private storage: Storage,
    private platform: Platform,
    private router: Router,
    private toastController: ToastController,
    private servvice: ApiService,
    private userSettings: UserSettingsService,
    private languageService: LanguageService
  ) {
    storage.create();
    this.showSplash();
  }

  async ngOnInit() {
    // SplashScreen.hide();

    // // Show the splash for two seconds and then auto hide:
    // SplashScreen.show('public/assets/animation.json',{
    //   showDuration: 2000,
    //   autoHide: true,
    // });
    this.storage.get('user').then(async (user) => {
      // console.log(user);
      if(user){
        await this.userSettings.get_user_settings({id: user.data.settings}).subscribe(async (settings:any) => {
          // console.log(settings);
          await this.languageService.setLanguage(settings.data.language);
        });
      }else{
        await this.languageService.getDefaultLanguage();
      }

    });


    if(this.platform.is('android')){
      const permission = await Geolocation.checkPermissions();
      if( permission.coarseLocation == 'denied' || permission.location == 'denied'){
       Geolocation.requestPermissions();
      }

      PushNotifications.requestPermissions().then(result => {
        if (result.receive === 'granted') {
          // Register with Apple / Google to receive push via APNS/FCM
          PushNotifications.register();
        } else {
          // Show some error
        }
      });

      await PushNotifications.addListener('registration',
        (token: Token) => {
          // console.log('Push registration success, token: ' + token.value);

          this.storage.get('user').then((user:any) => {
            if(user != null){
              this.servvice.update_informations({id: user.data.id, userName: token.value});
            }
          });
        }
      );

         // Show us the notification payload if the app is open on our device
         PushNotifications.addListener('pushNotificationReceived',
          (notification: PushNotificationSchema) => {
            this.presentToast(notification.title);
          }
        );

        // Method called when tapping on a notification
        PushNotifications.addListener('pushNotificationActionPerformed',
          (notification: ActionPerformed) => {
            this.router.navigate(['/notifications']);
          }
        );
    }

  }

  async presentToast(message: string) {
    const toast = await this.toastController.create({
      message: message,
      duration: 5000,
      position: 'top',
      mode:'ios',
      color: 'dark',
      swipeGesture: "vertical",
      icon: 'calendar-outline',
      buttons: [
        {
          text: 'Ok',
          handler:() => {this.router.navigate(['/notifications'])},
        }
      ]
    });

    await toast.present();
  }

  async showSplash(){
    this.platform.ready();

    const lottie = (window as any).lottie;

    if(this.platform.is('ios') && lottie){
      await lottie.splashscreen.hide();
      await lottie.splashscreen.show('public/assets/animation.json', false);
    }
  }

  open_popover(event:any){
    // console.log("open pop", event);
    this.popover.present(event);
  }
}
