import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy, Router } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  TranslateModule,
  TranslateLoader,
  TranslatePipe,
} from '@ngx-translate/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { IonicStorageModule } from '@ionic/storage-angular';
import { Drivers } from '@ionic/storage';
import  { DatePipe } from '@angular/common';
import { Storage } from '@ionic/storage';
// import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {ToastModule} from 'primeng/toast';
import {MessageService} from 'primeng/api';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';

// import { BackbuttonModule } from './components/backbutton/backbutton.module';

export function LanguageLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}
// const config: SocketIoConfig = { url: 'http://localhost:3000', options: {} };
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    // SocketIoModule.forRoot(config),
    IonicStorageModule.forRoot({
      name: '_sokna',
      driverOrder: [
        Drivers.IndexedDB,
        Drivers.LocalStorage,
        Drivers.SecureStorage,
      ]
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: LanguageLoader,
        deps: [HttpClient],
      },
    }),
    AppRoutingModule,
    HttpClientModule,
    ToastModule,
    BrowserAnimationsModule,
    // BackbuttonModule
  ],
  exports: [ TranslateModule ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }, DatePipe, MessageService, Storage, SocialSharing],
  bootstrap: [AppComponent],
})
export class AppModule {}
